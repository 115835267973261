import React from "react";
import Helmet from "react-helmet";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import IntroHeader from "../../components/IntroHeader";
import Calculators from "../../components/Calculators";

import img from "../../images/calculator_page_mark.png";

const CalculatorPage = () => {
    const header = (
        <Header
            active={["tools-and-resources", "loan-calculators"]}
            theme="blue-ribbon"
        />
    );

    return (
        <Layout hasHero={false} header={header}>
            <Helmet>
                <title>
                    Home Loan Calculator - Mortgage Refinance Calculator | Bay
                    Equity Home Loans
                </title>
                <meta
                    name="description"
                    content="Getting ready to apply for a home loan or refinance your existing loan? Use these home mortgage calculators to estimate what it will take to make your dream a reality."
                />
                <script
                    src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
            </Helmet>
            <IntroHeader
                header="Home loan calculators"
                par="Buying a home requires a good chunk of money. As uneasy as that might make you feel, keep this in mind: it’s better to know the numbers than to dive in blindly. Whether you’re trying to see how much you can afford or how much you need to save, use these home mortgage calculators to figure out what it will take to make your dream a reality."
                img={img}
                alt="Calculate Your Home Loan - Home Loan Calculators Icon | Bay Equity Home Loans"
                paddingBottom="0px"
            />
            <Calculators
                showCalculatorMortgage={true}
                showCalculatorRentVsBuy={true}
                showCalculatorHomeAffordability={true}
                showCalculatorRequiredIncome={true}
                showCalculatorRefinance={true}
            />
        </Layout>
    );
};
export default CalculatorPage;
